import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Typography, Container } from "@material-ui/core"
import SEO from "../components/seo"
import useSeoContent from "../hooks/use-seo-content"

export default function PrivacyPolicyPage({ location }) {
  const seo = useSeoContent()
  const { policy } = useStaticQuery(graphql`
    query {
      policy: file(
        sourceInstanceName: { eq: "data" }
        name: { eq: "privacy-policy" }
      ) {
        childMarkdownRemark {
          frontmatter {
            title
          }
          html
        }
      }
    }
  `)

  return (
    <>
      <SEO
        location={location}
        title={seo.privacypolicy_meta_title}
        description={seo.privacypolicy_meta_description}
      />
      <section style={{ paddingTop: "0" }}>
        <Container>
          <Typography variant="h2">
            {policy.childMarkdownRemark.frontmatter.title}
          </Typography>
          <Typography variant="body1">
            <div
              dangerouslySetInnerHTML={{
                __html: policy.childMarkdownRemark.html,
              }}
            />
          </Typography>
        </Container>
      </section>
    </>
  )
}
